(function(Site, $, undefined) {
  'use strict';

  Site.inview = function() {
    var $inview = $('.js-inview-simple').once('site-inview');
    if ($inview.length > 0) {
      $inview.each(function() {
        new window.ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.666666
          })
          .setClassToggle(this, 'is-visible')
          .addTo(Site.scrollmagic);
      });
    }

    $inview = $('.js-inview').once('site-inview');
    if ($inview.length > 0) {
      $inview.each(function() {
        new window.ScrollMagic.Scene({
            duration: $(this).height(),
            triggerElement: this
          })
          .setClassToggle(this, 'is-visible')
          .addTo(Site.scrollmagic);
      });
    }

    $inview = $('.js-inview-once').once('site-inview');
    if ($inview.length > 0) {
      $inview.each(function() {
        new window.ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.666666,
            reverse: false
          })
          .setClassToggle(this, 'is-visible')
          .addTo(Site.scrollmagic);
      });
    }
  };

}(window.Site = window.Site || {}, jQuery));
