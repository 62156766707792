(function(Site, $, undefined) {
  'use strict';

  Site.menuToggle = function(is_menu_open) {
    if (Site.is_menu_open !== is_menu_open) {
      $('.site-header__menu').toggleClass('is-active', is_menu_open);
      Site.$body.toggleClass('js-site-menu-open', is_menu_open);

      if (is_menu_open) {
        Site.fixPage();

        // Scroll content to the top.
        Site.scroll($('.site-header__primary-wrap'));
      } else {
        Site.unfixPage();
      }

      Site.is_menu_open = is_menu_open;
    }
  };

}(window.Site = window.Site || {}, jQuery));
