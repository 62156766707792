(function(Site, $, undefined) {
  'use strict';

  Site.form = function() {
    // Validate form.
    $('.form__form').once('site-form').each(function() {
      $(this).validate({
        ignore: '',
        errorClass: 'form__error',
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        onsubmit: true,
        errorPlacement: function(error, element) {
          error.insertAfter(element);
        },
        invalidHandler: function(event, validator) {},
        highlight: function(element, errorClass) {
          $(element).parents('.form__item').addClass('js-form-item-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form__item').removeClass('js-form-item-invalid');
        },
        rules: {
          'name': 'required',
          'phone': 'required',
          'email': {
            'required': true,
            'email': true
          }
        },
        messages: {
          'name': 'Your full name is required.',
          'phone': 'Your phone number is required.',
          'email': {
            'required': 'Your email address is required.',
            'email': 'Your email address must be in the format of name@domain.com'
          }
        },
        submitHandler: function(form) {
          if (!Site.is_ajaxing) {
            var $form = $(form);

            $.ajax({
              'type': 'POST',
              'url': Site.ajax.url,
              'data': $form.serialize(),
              'beforeSend': Site.beginAjax,
              'error': function(jqXHR, textStatus, errorThrown) {
                window.alert('We are sorry, there was an error trying to submit the form. Please try again later.');
                Site.endAjax();
              },
              'success': function(data, textStatus, jqXHR) {
                // Any response should be considered a failure.
                if (data === '') {
                  // Reset the form.
                  form.reset();
                  $('.range__slider').each(function() {
                    this.noUiSlider.reset();
                  });
                  $form.find('.js-form-item-filled').removeClass('js-form-item-filled');

                  // Show the thank you page.
                  window.location.href = '/thank-you/';
                } else {
                  window.alert(data);
                }
                Site.endAjax();
              }
            });
          }

          return false;
        }
      });
    });
  };

}(window.Site = window.Site || {}, jQuery));
