(function(Site, $, undefined) {
  'use strict';

  Site.init = function() {
    var AppView = Backbone.View.extend({
      el: 'body',
      initialize: function() {
        // Track Google Analytics.
        Site.router.on('route', function(route, params) {
          var url = Backbone.history.getFragment();
          Site.history.push(url);

          if (Site.history.length > 1) {
            if (!/^\//.test(url)) {
              url = '/' + url;
            }

            if (typeof window.ga === 'function') {
              window.ga('send', {
                'hitType': 'pageview',
                'page': url
              });
            }
          }
        });
      },
      events: {
        'click .hero': 'heroClick',
        'click .site-header__logo .js-internal-link': 'internalLinkClick',
        'click .site-header__primary-nav .js-internal-link': 'internalLinkClick',
        'click .site-header__secondary-nav .js-internal-link:not(.is-active)': 'internalLinkClick',
        'click .btn--close-swap.is-active': 'closePopupClick',
        'keydown': 'keydownHandler'
      },
      heroClick: function(event) {
        var $this = $(event.currentTarget),
            destination = $this.data('slug');

        event.preventDefault();

        if (destination === 'home') {
          Site.scrollTo($this.parent().height());
        } else {
          Site.app.goto(destination);
        }
      },
      internalLinkClick: function(event) {
        var destination = $(event.currentTarget).attr('href').replace(/(^\/+|\/+$)/mg, '');

        event.preventDefault();

        Site.app.goto(destination);
      },
      goto: function(destination) {
        // Make it possible to click the link of a currently active item.
        if (Backbone.history.getFragment() === destination + '/') {
          Site.menuToggle(false);
          Site.updateFragment('');
          Site.app.open(destination);
          return;
        }

        // Close any popups.
        Site.app.closePopup();

        if (destination === '') {
          // Clicking the header logo while the menu is open requires help.
          if (Site.is_menu_open) {
            Site.scroll_top = 0;
            Site.$body.removeClass('js-full-site-header');
            Site.menuToggle(false);
          } else {
            Site.scrollTo(0);
          }
        } else {
          Site.menuToggle(false);
        }

        Site.router.navigate(destination + '/', { trigger: true });
      },
      closePopupClick: function(event) {
        event.preventDefault();
        Site.app.closePopup();

        // Update path.
        if (Site.history.length > 1) {
          // Go back to previous page.
          window.history.back();
        } else {
          // Return to home page if this is the first page load.
          Site.router.navigate('', { trigger: true });
        }
      },
      keydownHandler: function(event) {
        switch (event.keyCode) {
          // Escape key.
          case 27:
            if (Site.$body.hasClass('js-popup-open') && !Site.$body.hasClass('js-site-lightbox') && !Site.$body.hasClass('js-site-overlay')) {
              Site.app.closePopupClick(event);
            }
            break;
        }
      },
      open: function(destination) {
        var hero_id = '#' + destination + '-page',
            overview_id = '#' + destination + '-overview',
            $section = $('#' + destination);

        // Change link to active.
        $('.js-internal-link')
          .removeClass('is-active')
          .filter('[href="/' + destination + '/"]')
          .addClass('is-active');

        if ($section.hasClass('popup')) {
          Site.fixPage();
          Site.$body.addClass('js-popup-open');
          $section.addClass('is-active');

          // Apply modular JS.
          Site.register();
          Site.form();
          Site.rangeInputs();
        } else {
          var wait = true;
          var $oldHero = $('.hero.is-active').not(hero_id);
          var $newHero = $(hero_id).addClass('is-active');

          Site.scrollTo(overview_id, function() {
            // Since the animate function works on both html and body we must
            // wait for the second of the two calls.
            if (wait) {
              wait = false;
              return;
            }

            // Apply modular JS.
            Site.panoramaViewer();
            Site.carousels();
            Site.variableWidthCarousels();
            Site.verticalImageCarousels();
            Site.inview();
            Site.tabs(hero_id);

            // The post-scroll jump. Only executed if there is an open section
            // above this new section which would normally cause the browser to
            // jump downward once the old section is hidden and it's height is
            // removed from the document height.
            if ($oldHero.length) {
              Site.scroll_top = Site.$document.scrollTop();
              var current_position = $oldHero.position().top;
              var new_position = $newHero.position().top;
              if (new_position > current_position) {
                var opened_height = $oldHero.parent().find('.ajax-content').outerHeight(true);
                var jump_to = (Site.scroll_top - opened_height) + Site.$header.height();
                var margin_top = parseInt($newHero.css('marginTop'), 10);
                Site.scrollTo(jump_to - margin_top, false, true);
              }
              $oldHero.removeClass('is-active');
            }
          });
        }
      },
      closePopup: function() {
        Site.unfixPage();
        Site.$body.removeClass('js-popup-open');
        $('.popup.is-active').removeClass('is-active');
        $('.js-internal-link').removeClass('is-active');
      }
    });

    var AppRouter = Backbone.Router.extend({
      routes: {
        '*default(/)': 'goto'
      },
      goto: function(path) {
        var content_selector = '#' + path + '-page ~ .ajax-content, #' + path + ' .ajax-content';

        if (path === null) {
          // Do nothing on the home page.
          return;
        } else if (Site.isSelectorValid(content_selector)) {
          var $content = $(content_selector);

          if ($content.length) {
            // Load the content.
            if ($content.is(':empty')) {
              $.ajax({
                'type': 'GET',
                'cache': false,
                'dataType': 'html',
                'url': '/' + path + '/',
                'beforeSend': Site.beginAjax,
                'success': function(response) {
                  Site.scroll_top = Site.$document.scrollTop();
                  var $ajax_content = $('<div>').append($.parseHTML(response)).find(content_selector);
                  $content.replaceWith($ajax_content);
                  Site.endAjax();
                  var $imgs = $(content_selector).find('img');
                  // See here: https://stackoverflow.com/questions/45487105/ajax-loaded-images-in-safari-not-respecting-srcset
                  $imgs.each(function(index, img) {
                    img.outerHTML = img.outerHTML;
                  });
                  Site.$window.trigger('newajaxcontent');
                  // Lazy load the images.
                  // window.lazyLoadInstance.update();
                  Site.app.open(path);
                }
              });
            } else {
              Site.app.open(path);
            }

            return;
          }
        }

        // 404 on a a section without a stub.
        window.location.href = '/page-not-found/';
      }
    });

    Site.router = new AppRouter();
    Site.app = new AppView();

    Backbone.history.start({
      pushState: true
    });
  };

}(window.Site = window.Site || {}, jQuery));
