(function(Site, $, undefined) {
  'use strict';

  Site.header = function() {
    $('.site-header__menu').on('click', function(event) {
      Site.menuToggle(!Site.is_menu_open);
    });

    // Close the overlay with escape key.
    Site.$document.on('keyup.header', function(event) {
      switch (event.keyCode) {
      // Escape key.
      case 27:
        Site.menuToggle(false);
        break;
      }
    });

    // Scrolling.
    if (Site.$body.hasClass('app')) {
      var previous_state = false,
          scroll_fn;

      scroll_fn = function() {
        if (Site.is_menu_open || Site.is_page_fixed) {
          return;
        }

        var scroll_top = parseInt(Site.$window.scrollTop(), 10),
            is_past_100vh = scroll_top >= (Site.$window.height() - 60);

        if (previous_state !== is_past_100vh) {
          previous_state = is_past_100vh;
          Site.$body.toggleClass('js-full-site-header', is_past_100vh);
        }
      };

      Site.$window.on('scroll.header resize.header', scroll_fn);
      scroll_fn();
    } else {
      Site.$body.addClass('js-full-site-header');
    }
  };

}(window.Site = window.Site || {}, jQuery));
