/**
 * overlay
 *
 * Show an overlay.
 */

(function(Site, $, undefined) {
  'use strict';

  Site.overlay = {
    overlays: [
      '#accessibility',
      '#terms-of-use'
    ],
    alt_overlays: [
      // '#thank-you'
    ],
    init: function() {
      // Click to open an overlay.
      Site.$document.on('click.overlay-open', '.js-site-overlay-trigger', function(event) {
        var $this = $(this),
            fragment = $this.attr('href');

        event.preventDefault();

        Site.last_fragment = window.location.hash;
        Site.updateFragment(fragment);
        Site.overlay.open(fragment, $.inArray(fragment, Site.overlay.alt_overlays) !== -1);
      });

      // Close the overlay with the close button.
      Site.$document.on('click.overlay-close', '.js-overlay-close', Site.overlay.close);

      // Close the overlay with escape key.
      Site.$document.on('keyup.overlay', function(event) {
        switch (event.keyCode) {
        // Escape key.
        case 27:
          Site.overlay.close(event);
          break;
        }
      });

      // Open the overlay on hashchange event.
      Site.$window.bind('hashchange.overlay', function(event) {
        var hash = window.location.hash;

        if ($.inArray(hash, Site.overlay.overlays) !== -1) {
          Site.overlay.open(hash, false);
        } else if ($.inArray(hash, Site.overlay.alt_overlays) !== -1) {
          Site.overlay.open(hash, true);
        } else if (Site.$body.hasClass('js-site-overlay')) {
          Site.overlay.close(event);
        } else if (Site.isSelectorValid(hash)) {
          Site.scrollTo(hash);
          Site.last_fragment = hash;
        }
      });
    },
    close: function(event) {
      var $this = $(event.currentTarget);

      event.preventDefault();

      Site.$body.removeClass('js-site-overlay');
      $('.site-overlay__close').blur();

      // Wait for CSS transition to finish.
      setTimeout(function() {
        Site.unfixPage();
        setTimeout(function() {
          Site.updateFragment(Site.last_fragment);
        }, 200);
      }, 200);
    },
    open: function(fragment, use_alt) {
      Site.fixPage();

      // Open overlay.
      $('.site-overlay__content').not(fragment).hide();
      $(fragment).show();

      Site.$body.addClass('js-site-overlay');
      // Scroll content to the top.
      Site.scroll(Site.$overlay.toggleClass('site-overlay--alt', use_alt));
    }
  };

}(window.Site = window.Site || {}, jQuery));
