(function(Site, $, undefined) {
  'use strict';

  Site.inputs = function() {
    // Focus field class.
    Site.$document.on('focus.form', '.form__input', function(event) {
      $(this).parents('.form__item').addClass('js-form-item-filled js-form-item-focused');
    });

    // Blur field class.
    Site.$document.on('blur.form', '.form__input', function(event) {
      var $this = $(this),
          is_valid = $this.is(':invalid'),
          is_filled = $this.val() === '' || $this.val() === null;

      $this.parents('.form__item')
        .removeClass('js-form-item-focused')
        .toggleClass('js-form-item-filled', !is_filled)
        .toggleClass('js-form-item-invalid', is_valid);
    });
  };

}(window.Site = window.Site || {}, jQuery));
