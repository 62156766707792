(function(Site, $, undefined) {
  'use strict';

  var $window = $(window),
      $document = $(document);

  Site = $.extend(Site, {
    '$window': $window,
    '$document': $document,
    '$scrollable': undefined,
    '$body': undefined,
    '$header': undefined,
    '$footer': undefined,
    '$overlay': undefined,
    '$lightbox': undefined,
    '$lightbox_content': undefined,
    'history': [],
    'tabs_scene': undefined,
    'tabs_next_section_scene': undefined,
    'scroll_top': 0,
    'last_fragment': '',
    'webgl_max_dimension': 0,
    'scrollmagic': undefined,
    'is_menu_open': false,
    'is_page_fixed': false,
    'is_ajaxing': false,
    'is_safari': /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window.safari || window.safari.pushNotification),
    'is_ie11': (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject),
    beginAjax: function(xhr) {
      Site.is_ajaxing = true;
      Site.$body.addClass('is-loading');
      if (Site.ajax.hasOwnProperty('username')) {
        var auth = Site.ajax.username + ':' + Site.ajax.password;
        xhr.setRequestHeader('Authorization', 'Basic ' + window.btoa(auth));
      }
    },
    endAjax: function() {
      Site.is_ajaxing = false;
      Site.$body.removeClass('is-loading');
    },
    fixPage: function() {
      if (!Site.is_page_fixed) {
        Site.is_page_fixed = true;
        // Save scroll top position.
        Site.scroll_top = Site.$document.scrollTop();
        // Adjust page position.
        Site.$body.css({
          'width': Site.$window.width(),
          'marginTop': -Site.scroll_top
        });
        Site.$body.addClass('js-page-is-fixed');
      }
    },
    unfixPage: function() {
      if (Site.is_page_fixed) {
        Site.is_page_fixed = false;
        Site.$body.css({
          'width': '',
          'marginTop': ''
        });
        Site.$body.removeClass('js-page-is-fixed');
        // Restore scroll position.
        Site.$document.scrollTop(Site.scroll_top);
      }
    },
    scrollTo: function(arg, callback, immediately) {
      var position = arg,
          header_offset = Site.$header.height();

      // Get position if arg is a selector.
      if (typeof arg === 'string') {
        var $arg = $(arg);

        if ($arg.length <= 0) {
          return;
        }

        position = $arg.offset().top - parseInt($arg.css('marginTop'), 10);

        // All sections except the overview section should account for the tabs.
        if (arg.substr(-9) !== '-overview') {
          header_offset = header_offset + $('.tabs').height();
        }
      }

      position = Math.max(0, position - header_offset);
      if (true === immediately) {
        Site.$scrollable.scrollTop(position);
      } else {
        Site.scroll(Site.$scrollable, position, false, callback);
      }
    },
    scroll: function($el, position, horizontal, callback) {
      position = position === undefined ? 0 : position;
      var options = {
        duration: 300,
        queue: false
      };

      if ('function' === typeof callback) {
        options.complete = callback;
      }

      var properties;
      if (true === horizontal) {
        properties = { scrollLeft: position };
      } else {
        properties = { scrollTop: position };
      }

      $el.stop(false, true).animate(properties, options);
    },
    updateFragment: function(fragment) {
      // Update URL fragment.
      if (window.history.pushState && fragment !== window.location.hash) {
        if (fragment === '') {
          fragment = window.location.pathname;
        }
        window.history.pushState(null, null, fragment);
      }
    },
    maxLengthCheck: function(object) {
      if (object.value.length > object.maxLength) {
        object.value = object.value.slice(0, object.maxLength);
      }
      var val = parseInt(object.value, 10);
      if (val > parseInt(object.max, 10)) {
        object.value = object.max;
      }
    },
    isNumeric: function(event) {
      var theEvent = event || window.event;
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }
      }
    },
    getMaxHeight: function($els) {
      var max_height = 0;
      $els.each(function(i, val) {
        var $it = $(this);
        var h = $it.outerHeight();
        max_height = h > max_height ? h : max_height;
      });
      return max_height;
    },
    isSelectorValid: function(selector) {
      var dummy = document.createElement('br');

      return function(selector) {
        try {
          dummy.querySelector(selector);
        } catch (e) {
          return false;
        }

        return true;
      };
    },
    shrinkWrapContainerToImg: function($els) {
      $els.css('width', '').each(function(i, val) {
        var $this = $(this),
            w = $this.parent().width(),
            cw = $this.find('img').width();
        if (cw > 0 && w > cw) {
          $this.css('width', cw);
        }
      });
    }
  });

  $(function() {
    // DOM Ready...
    $('html').removeClass('no-js').addClass('js');

    // Fix heights for IE11's lack of flexbox support.
    if (Site.is_ie11) {
      var fixie11 = function() {
        $('.full-screen--short').each(function(i, val) {
          $(this).height(Site.$window.height() - 104);
        });

        $('.carousel__media').each(function(i, val) {
          var $this = $(this),
              $img = $this.find('img');
          if ($img.length) {
            $this.width($img.width());
          }
        });
      };

      fixie11();
      Site.$window.debounce('resize.fixie11 newajaxcontent.fixie11', fixie11, 200);
    }

    Site.$scrollable = $('html, body');
    Site.$body = $('body');
    Site.$header = $('.site-header');
    Site.$footer = $('.site-footer');
    Site.$overlay = $('.site-overlay');
    Site.$lightbox = $('.site-lightbox');
    Site.$lightbox_content = $('.site-lightbox__content');

    // Set global properties.
    var canvas = document.createElement('canvas'),
        gl = canvas.getContext('webgl');

    if (gl !== null) {
      Site.webgl_max_dimension = gl.getParameter(gl.MAX_RENDERBUFFER_SIZE);
    }

    Site.scrollmagic = new window.ScrollMagic.Controller();

    // Use mustache-like template replacements.
    _.templateSettings.interpolate = /\{\{(.+?)\}\}/g;

    // Initiate one-time modules.
    Site.header();
    Site.overlay.init();
    Site.lightbox.init();
    Site.inputs();

    if (Site.$body.hasClass('app')) {
      Site.init();
    }
  });

  $window.on('load', function(event) {
    // Page loaded...
    // If the URL contains a fragment go there.
    if (window.location.hash !== '') {
      Site.$window.trigger('hashchange');
    }
  });

}(window.Site = window.Site || {}, jQuery));
