(function(Site, $, undefined) {
  'use strict';

  Site.rangeInputs = function() {
    var $range_inputs = $('.range').once('site-range-inputs');

    if ($range_inputs.length) {
      $range_inputs.each(function() {
        var range = this,
            $this = $(this),
            $slider = $this.find('.range__slider'),
            slider = $slider.get(0),
            $min = $this.find('.range__min'),
            $max = $this.find('.range__max'),
            $inputs = $this.siblings('.form__range-mirror').find('input'),
            step = parseInt($inputs.first().attr('step'), 10),
            min = parseInt($inputs.first().attr('min'), 10),
            max = parseInt($inputs.first().attr('max'), 10);

        // Skip focusing the range inputs.
        $inputs.attr('tabindex', -1);

        // Create noUISlider.
        window.noUiSlider.create(slider, {
          step: step,
          start: [min, max],
          connect: true,
          cssPrefix: 'range__',
          range: {
            'min': min,
            'max': max
          }
        });

        // Update mirrored range inputs with this noUISlider.
        slider.noUiSlider.on('update', function(values, handle) {
          var value = Math.round(values[handle]),
              formatted_value = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // Update respective range inputs.
          $inputs.eq(handle).val(value);

          // Update the display text.
          if (handle) {
            $max.text(formatted_value);
          } else {
            $min.text(formatted_value);
          }
        });

        // Add keyboard support to noUISlider.
        $slider.find('.range__handle').on('keydown', function(event) {
          var handle = event.currentTarget.dataset.handle,
              values = slider.noUiSlider.get(),
              value = Number(values[handle]),
              new_values = [null, null];

          switch (event.which) {
          case 37:
            new_values[handle] = value - step;
            break;
          case 39:
            new_values[handle] = value + step;
            break;
          }

          // Update the slider
          slider.noUiSlider.set(new_values);
        });
      });
    }
  };

}(window.Site = window.Site || {}, jQuery));
