(function(Site, $, undefined) {
  'use strict';

  Site.register = function() {
    var $form_swap_items = $('.js-form-swap').once('site-register');

    if ($form_swap_items.length) {
      // Swap forms.
      Site.$document.on('click.form-swap', '.js-form-swap', function(event) {
        event.preventDefault();
        $form_swap_items.removeClass('is-active');

        var form_id = $(this).addClass('is-active').attr('href');
        $('.contact__form').not(form_id).hide();
        $(form_id).show();
      });

      $('#broker').hide();
    }
  };

}(window.Site = window.Site || {}, jQuery));
