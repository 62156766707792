(function(Site, $, undefined) {
  'use strict';

  Site.verticalImageCarousels = function() {
    $('.js-vertical-image-carousel').each(function(index, value) {
      var $this = $(this);
      var $slides = $this.find('.carousel__slides').not('.slick-initialized');
      var $slide = $slides.find('.carousel__slide');
      var update_title_caption_height = function() {
        // Reset layout.
        var $caption = $this.find('.carousel__figcaption');
        $caption.removeClass('js-height-set').css('height', '');
        var h = Site.getMaxHeight($caption);

        // Set new layout.
        $caption.css('height', h).addClass('js-height-set');
        $this.find('.carousel__btn').css('top', 'calc((100% - ' + h + 'px) / 2)');

        Site.shrinkWrapContainerToImg($this.find('.carousel__media'));
      };

      if ($slide.length <= 1) {
        return;
      }

      $slides.on('init', function(event, slick) {
        var $that = $(this);
        var $carousel = $that.parents('.carousel');

        // Only show currently active title/caption.
        update_title_caption_height();

        $carousel.find('.carousel__previous').click(function(event) {
          $(this).siblings('.carousel__slides').slick('slickPrev');
        });
        $carousel.find('.carousel__next').click(function(event) {
          $(this).siblings('.carousel__slides').slick('slickNext');
        });
      });

      // $slides.debounce('lazyLoaded', function(event, slick, image, imageSource) {
      //   update_title_caption_height();
      //   slick.setPosition();
      // }, 200);

      Site.$window.debounce('resize.vertical-image-carousels', update_title_caption_height, 200);

      $slides.waitForImages(true).done(function() {
        $slides.slick({
          arrows: false,
          speed: 300,
          centerMode: true,
          variableWidth: true,
          // lazyLoad: 'progressive',
          // Fix for extra divs. See: https://github.com/kenwheeler/slick/issues/3207
          rows: 0,
          // Safari doesn't handle translate3d() well.
          useTransform: !Site.is_safari,
          responsive: [
            {
              breakpoint: 769,
              settings: {
                centerMode: false,
                variableWidth: false
              }
            }
          ]
        });
      });
    });
  };

}(window.Site = window.Site || {}, jQuery));
