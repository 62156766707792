(function(Site, $, undefined) {
  'use strict';

  Site.tabs = function(selector) {
    var $el = $(selector),
        $next_section = $el.closest('section').next('section.page'),
        $content = $el.siblings('.ajax-content'),
        $this = $content.find('.tabs');

    if ($this.length <= 0) {
      return;
    }

    if (Site.tabs_scene) {
      Site.tabs_scene.remove();
      Site.$body.removeClass('js-tabs-stuck');
    }

    if (Site.tabs_next_section_scene) {
      Site.tabs_next_section_scene.remove();
    }

    // Mark the current section.
    // TODO: Use a more precise way to wait for element height.
    setTimeout(function() {
      Site.tabs_scene = new window.ScrollMagic.Scene({
          // offset: $this.height() + 1,
          triggerElement: $content.get(0),
          triggerHook: 'onLeave'
        })
        .setClassToggle(Site.$body.get(0), 'js-tabs-stuck')
        .addTo(Site.scrollmagic);

      // Un-stick tabs when entering next section.
      if ($next_section.length) {
        Site.tabs_next_section_scene = new window.ScrollMagic.Scene({
          offset: (Site.$header.height() + $this.height()) * -1,
          triggerElement: $next_section.get(0),
          triggerHook: 'onLeave'
        })
        .on('enter', function(event) {
          Site.$body.removeClass('js-tabs-stuck');
        })
        .on('leave', function(event) {
          Site.$body.addClass('js-tabs-stuck');
        })
        .addTo(Site.scrollmagic);
      }

      var $tab_section = $('.js-tab-section').once('site-tabs');
      if ($tab_section.length > 0) {
        $tab_section.each(function() {
          new window.ScrollMagic.Scene({
              duration: $(this).height(),
              triggerElement: this
            })
            .on('enter', function(event) {
              var id = event.target.triggerElement().id;
              var $that = $('a[href$="' + id + '"]');
              var $li = $that.parent();
              var $list = $that.closest('.tabs__items');

              // Mark active class.
              $list.find('.is-active').removeClass('is-active');
              $that.addClass('is-active');

              // Scroll to item.
              var offset;
              var list_left = $list.position().left;
              var viewer_width = $list.parent().width() - list_left;
              var current_index = $li.index();
              var item_left = 0;
              var item_width = 0;
              $list.children().each(function(index) {
                var w = parseInt($(this).outerWidth(true), 10);
                if (index < current_index) {
                  item_left += w;
                }
                if (index === current_index) {
                  item_width = w;
                }
              });

              if (item_width < viewer_width && item_left > 0) {
                offset = (item_left - (viewer_width - item_width) / 2);
              }
              else {
                offset = item_left;
              }

              Site.scroll($list, offset, true);
            })
            .addTo(Site.scrollmagic);
        });
      }
    }, 300);

    // Tab click handler.
    $this.find('a').once('site-tabs-link').bind('click.tabs', function(event) {
      event.preventDefault();

      // Update the fragment without jumping.
      var hash = '#' + $(this).blur().attr('href').split('#')[1];
      if (history.pushState) {
        history.pushState(null, null, hash);
        Site.scrollTo(hash);
      } else {
        window.location.hash = hash;
        // Site.overlay will handle the Site.scrollTo() call.
      }
    });
  };

}(window.Site = window.Site || {}, jQuery));
