(function(Site, $, undefined) {
  'use strict';

  Site.lightbox = {
    init: function() {
      Site.$document.on('click.lightbox', '.js-image-lightbox', function(event) {
        var $this = $(event.currentTarget),
            $carousel = $this.parents('.carousel'),
            $images = $carousel.find('.carousel__slide').not('.slick-cloned').find('.carousel__image'),
            images_count = $images.length;

        event.preventDefault();
        Site.$lightbox_content.empty();
        Site.$lightbox.removeClass('site-lightbox--blue');

        var carousel = _.template('<div class="carousel carousel--lightbox full-screen text-centered text-blue js-lightbox-carousel"><div class="carousel__wrap"><div class="carousel__slides">{{ slides }}</div></div></div>');
        var slide = _.template('<figure class="carousel__slide"><div class="carousel__figure"><div class="carousel__media-wrap flex-center"><span class="carousel__media">{{ nav }}<img class="{{ classes }}" src="{{ src }}" alt="{{ alt }}"><span class="carousel__count p--loose bold text-white"><span>{{ i }}</span>/{{ images_count }}</span></span></div></div><figcaption class="carousel__figcaption"><div class="wysiwyg wysiwyg--tight"><p>{{ caption }}</p></div></figcaption></figure>');

        var nav = $('.site-lightbox__close').clone().toggleClass('site-lightbox__close carousel__close').wrap('<div></div>').parent().html();
        if (images_count > 1) {
          nav += $carousel.find('.carousel__previous').clone().wrap('<div></div>').parent().html();
          nav += $carousel.find('.carousel__next').clone().wrap('<div></div>').parent().html();
        }

        var slides = '';
        $images.each(function(i, val) {
          slides += slide({
            'i': i + 1,
            'nav': nav,
            'images_count': images_count,
            'alt': this.alt,
            'src': this.dataset.lightbox,
            'classes': this.className,
            'caption': this.dataset.caption
          });
        });

        Site.$lightbox_content.append(carousel({ slides: slides }));
        Site.lightboxCarousels(event.currentTarget.dataset.index);

        Site.lightbox.open();
      });

      Site.$document.on('click.lightbox', '.js-residence-lightbox', function(event) {
        var $this = $(event.currentTarget),
            id = $this.parent().data('residence-id'),
            lightbox = $('.js-residence-lightbox-details[data-residence-id="' + id + '"]').html();

        event.preventDefault();
        Site.$lightbox_content.empty();
        Site.$lightbox.addClass('site-lightbox--blue');

        Site.$lightbox_content.append(lightbox);

        Site.lightbox.open();
      });

      // Close the lightbox with the close button.
      Site.$document.on('click.lightbox-close', '.js-lightbox-close', Site.lightbox.close);

      // Close the lightbox with escape key.
      Site.$document.on('keyup.lightbox', function(event) {
        switch (event.keyCode) {
        // Escape key.
        case 27:
          Site.lightbox.close(event);
          break;
        }
      });
    },
    close: function(event) {
      var $this = $(event.currentTarget);

      event.preventDefault();

      Site.$body.removeClass('js-site-lightbox');
      $('.site-lightbox__close').blur();
      Site.unfixPage();
    },
    open: function(fragment, use_alt) {
      Site.fixPage();
      Site.$body.addClass('js-site-lightbox');

      // Scroll content to the top.
      Site.scroll(Site.$lightbox);
    }
  };

}(window.Site = window.Site || {}, jQuery));
