/**
 * panoramaViewer
 *
 * A panorama viewer for multiple 8,000 by 4,000 pixel 2:1 Equirectangular
 * projection renderings. Powered by Pannellum v2.3.2.
 *
 * https://pannellum.org/documentation/reference/
 */

(function(Site, $, undefined) {
  'use strict';

  Site.panoramaViewer = function() {
    var $viewer = $('#panorama-viewer');

    if ($viewer.length <= 0 || $viewer.hasClass('.js-pannellum-initialized')) {
      return;
    } else {
      $viewer.addClass('js-pannellum-initialized');
    }

    // Create panorama viewer.
    var resolution = Site.webgl_max_dimension >= 6000 ? '6000x3000' : '4096x2048',
        viewer = window.pannellum.viewer('panorama-viewer', {
      'default': {
        'type': 'equirectangular',
        'autoLoad': true,
        'showControls': false,
        'keyboardZoom': false,
        'mouseZoom': false,
        'basePath': Site.theme_uri + '/images/',
        'firstScene': 'scene1',
        'sceneFadeDuration': 1000
      },
      'scenes': {
        'scene1': {
          'panorama': 'view1-' + resolution + '.jpg'
        },
        'scene2': {
          'panorama': 'view2-' + resolution + '.jpg'
        },
        'scene3': {
          'panorama': 'view3-' + resolution + '.jpg'
        }
      }
    });

    // Restart auto-pan after five seconds.
    Site.$window.bind('panoramaAutoStart', function(event) {
      setTimeout(viewer.startAutoRotate, 1000);
    });

    new window.ScrollMagic.Scene({
        triggerElement: $viewer[0]
      })
      .on('start', function(event) {
        if (event.scrollDirection === 'FORWARD') {
          Site.$window.trigger('panoramaAutoStart');
        }
      })
      .addTo(Site.scrollmagic);

    // Change view buttons.
    Site.$document.on('click.panorama-viewer-scene', '.panorama__scene-btn', function(event) {
      $('.panorama__scene-btn.is-active').removeClass('is-active');
      $(this).addClass('is-active');
      viewer.loadScene(this.dataset.sceneId);
    });

    // Close button.
    Site.$document.on('click.panorama-viewer-close', '.panorama__close-btn', function(event) {
      var $this = $(this).toggleClass('is-active'),
          is_pressed = $this.attr('aria-pressed') === 'true';

      $this.parents('.controls').toggleClass('controls--closed');

      // Set the new aria-pressed state on the button.
      $this.attr('aria-pressed', is_pressed ? 'false' : 'true');
      $this.find('span').text(is_pressed ? 'Menu' : 'Close');
    });

    // Full-screen button.
    Site.$document.on('click.panorama-viewer-fullscreen', '.panorama__fullscreen-btn', function(event) {
      var $this = $(this),
          is_pressed = $this.attr('aria-pressed') === 'true';

      viewer.toggleFullscreen();

      // Set the new aria-pressed state on the button.
      $this.attr('aria-pressed', is_pressed ? 'false' : 'true');
      $this.find('title').text(is_pressed ? 'Full screen' : 'Close');
      $this.find('use').attr('xlink:href', is_pressed ? '#enter-fullscreen' : '#exit-fullscreen');
    });
  };

}(window.Site = window.Site || {}, jQuery));
