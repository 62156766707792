(function(Site, $, undefined) {
  'use strict';

  Site.variableWidthCarousels = function() {
    $('.js-variable-width-carousel').each(function(index, value) {
      var $this = $(this);
      var $slides = $this.find('.carousel__slides').not('.slick-initialized');
      var $slide = $slides.find('.carousel__slide');
      var update_title_caption_height = function() {
        // var $title = $this.find('.carousel__title');
        var $caption = $this.find('.carousel__caption');
        // Reset layout.
        // $title.removeClass('js-height-set').css('height', '');
        $caption.removeClass('js-height-set').css('height', '');
        // Set new layout.
        // $title.css('height', Site.getMaxHeight($title.find('span'))).addClass('js-height-set');
        $caption.css('height', Site.getMaxHeight($caption.find('p'))).addClass('js-height-set');
      };

      if ($slide.length <= 1) {
        return;
      }

      $slides.on('init', function(event, slick) {
        var $that = $(this);
        var $carousel = $that.parents('.carousel');

        // Only show currently active title/caption.
        update_title_caption_height();

        $carousel.find('.carousel__previous').click(function(event) {
          $(this).siblings('.carousel__slides').slick('slickPrev');
        });
        $carousel.find('.carousel__next').click(function(event) {
          $(this).siblings('.carousel__slides').slick('slickNext');
        });
      });

      $slides.on('beforeChange', function(event, slick, currentSlide) {
        slick.$slider.parents('.carousel').addClass('carousel--transitioning');
      });

      $slides.on('afterChange', function(event, slick, currentSlide) {
        var $that = $(this);
        var $carousel = $that.parents('.carousel');

        // Update title/caption elements outside of carousel.
        // $carousel.find('.carousel__title span').removeClass('is-active')
        //   .eq(currentSlide).addClass('is-active');
        $carousel.find('.carousel__caption p').removeClass('is-active')
          .eq(currentSlide).addClass('is-active');

        $carousel.removeClass('carousel--transitioning');
      });

      // $slides.debounce('lazyLoaded', function(event, slick, image, imageSource) {
      //   update_title_caption_height();
      //   slick.setPosition();
      // }, 200);

      Site.$window.debounce('resize.carousels', update_title_caption_height, 200);

      $slides.waitForImages(true).done(function() {
        $slides.slick({
          arrows: false,
          speed: 300,
          centerMode: true,
          variableWidth: true,
          // lazyLoad: 'progressive',
          // Fix for extra divs. See: https://github.com/kenwheeler/slick/issues/3207
          rows: 0,
          // Safari doesn't handle translate3d() well.
          useTransform: !Site.is_safari,
          responsive: [
            {
              breakpoint: 769,
              settings: {
                centerMode: false,
                variableWidth: false
              }
            }
          ]
        });
      });
    });
  };

}(window.Site = window.Site || {}, jQuery));
